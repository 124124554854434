@import "../../scss/variables";

.filter-brand {
  display: flex;
  flex-direction: column;
  &::after {
    content: "";
    width: 100%;
    border-bottom: solid 1px $lineColor;
  }
  &__search {
    border: 1px solid $inputColor;
    border-radius: 5px;
    padding: 8px 12px;
    width: 100%;
    margin-bottom: 16px;

    &::placeholder {
      color: $placeholderColor;
    }
  }
  &__show-all {
    font-size: 14px;
    line-height: 20px;
    color: $buttonColor;
    cursor: pointer;
    margin-bottom: 16px;
  }
}
