@import "../../scss/variables";

.pagination {
  margin-bottom: 100px;

  & ul {
    display: flex;
    max-width: 300px;
    justify-content: space-around;
    cursor: pointer;
    margin: 0 auto;

    & a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $inputColor;
      border-radius: 5px;

      &:hover {
        border-color: $buttonColor;
      }
    }
  }
}

.selected a {
  color: $whiteColor;
  background-color: $buttonColor;
  border-color: $buttonColor !important;
}

.disable-arrow {
  display: none;
}
