@import "../../scss/variables";

.catalog_filter {
  &-main {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      max-width: 200px;
    }
  }

  &-title {
    font-size: 18px;
    line-height: 28px;
    color: $textColor;
    margin: 16px 0;

    &-arrow {
      height: 24px;
      width: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
