@import "../../scss/variables";

.footer {
  width: 100%;
  background-color: $footerColor;
  border-radius: 30px 30px 0 0;

  &_menu-list {
    display: flex;
    padding: 32px 0;
    max-width: 400px;
    justify-content: space-between;

    @media (max-width: 1340px) {
      margin: 0 auto;
    }
    @media (max-width: 420px) {
      margin: 0 12px;
    }
  }

  &_menu-link {
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $textColor;
    font-family: "Rubik", sans-serif;
    transition: all .4s ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 420px) {
      font-size: 16px;
    }
  }
}
