@import "../../scss/variables";

.sizeForm {
  max-width: 872px;
  padding-bottom: 16px;

  .checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;

    .label {
      display: flex;
      align-items: center;
      margin-right: 32px;
      cursor: pointer;

      .checkbox {
        display: none;
      }

      .fake {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
        border: 2px solid $inputColor;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 6px solid $buttonColor;
          opacity: 0;
        }
      }

      .checkbox:checked + .fake::before {
        opacity: 1;
      }

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textColor;
      }
    }
  }

  .input-wrapper {
    max-width: 776px;
    display: flex;
    justify-content: space-between;
    margin: 28px 0 0;

    @media (max-width: 876px) {
      flex-wrap: wrap;
    }

    @media (max-width: 690px) {
      flex-direction: column;
    }

    .input {
      width: 100%;
      max-width: 192px;
      padding: 15px 28px 11px;
      border: 1px solid $inputColor;
      border-radius: 5px;
      font-size: 18px;
      line-height: 28px;
      background-color: transparent;

      &::placeholder {
        color: $placeholderColor;
      }

      @media (max-width: 690px) {
        max-width: 358px;
      }

      &:focus {
        border-color: $buttonColor;

        & + .label {
          color: $buttonColor;
        }
      }

      &-error {
        @media (max-width: 876px) {
          margin-bottom: 20px;
        }
      }
    }

    .react-datepicker__input-container {
      input {
        padding-right: 45px;
      }
    }
    .react-datepicker__close-icon {
      top: 50%;
      right: 8px;
      width: 36px;
      height: 36px;
      margin-top: -18px ;
      padding: 0;

      &::after {
        top: 0;
        width: 36px;
        height: 36px;
        padding: 0;
        font-size: 22px;
        background-color: transparent;
        border-radius: 0;
        color: $mainColor;
        transition: all 0.3s ease-in-out;
      }
      &:hover,&:active {
        &::after {
          color: $buttonColor;
        }
      }
    }

    .label-wrapper {
      position: relative;
    }

    .label {
      position: absolute;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $textColor;
      padding: 0 8px;
      top: -8px;
      left: 20px;
    }

    .button {
      cursor: pointer;
      display: inline-block;
      padding: 16px 32px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      max-height: 56px;
      max-width: 126px;
      color: $whiteColor;
      border: 1px solid $mainSecondColor;
      background-color: $mainSecondColor;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: transparent;
        color: $mainSecondColor;
        border: 1px solid $mainSecondColor;
      }
    }
  }
}

.input-error {
  font-size: 12px;
  line-height: 20px;
  color: $mainSecondColor;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $textColor !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0.3;
  cursor: pointer;
}

input[type="date"] {
  width: 100%;
  max-width: 358px;
  color: $textColor;
  padding: 14px 25px 10px !important;
}
