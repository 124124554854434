@import "../../scss/variables";

.country {
  display: flex;
  align-items: center;
  cursor: pointer;

  &-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    margin-right: 4px;
    display: flex;

    & > * {
      color: $titleColor;
      margin-right: 2px;
    }
  }

  &-wrapper {
    padding: 32px;

    &-title {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    &-location {
      &::after {
        content: "";
        display: block;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
        border-bottom: solid 1px $lineColor;
      }
    }
  }
}

.box select {
  background-color: transparent;
  padding: 8px 12px;
  width: 256px;
  border: 1px solid $inputColor;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    padding: 8px 16px;
    border: 1px solid #CACACA;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 12px;

    &:hover {
      color: $buttonColor;
      border-color: $buttonColor;
    }
  }
}

.active-lang {
  color: $buttonColor;
  border-color: $buttonColor;
}

.css-1j0nstl-control {
  background-color: transparent;
  padding: 4px 12px;
  width: 256px;
  border: 1px solid $inputColor;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  display: flex;
  align-items: center;
}

.css-1ko62if-control {
  background-color: transparent;
  padding: 4px 12px;
  width: 256px;
  border: 1px solid $inputColor;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  display: flex;
  align-items: center;
}

.css-1pahdxg-control:hover {
  border-color: $inputColor;
}

.css-319lph-ValueContainer {
  margin: 0;
  padding: 0;
}

.css-6j8wv5-Input {
  margin: 0;
  padding: 0;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}