@import "../../scss/variables";

.catalog_list-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin-left: -12px;
  @media (max-width: 624px) {
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}

.catalog_list {
  max-width: 984px;
  flex-grow: 1;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &-results {
    & > * {
      margin-right: 5px;
      font-size: 18px;
      line-height: 28px;
      color: $placeholderColor;

      @media (max-width: 720px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &-sort {
    display: flex;
    align-items: center;
    width: 147px;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &-button {
    width: 100%;
    border: 1px solid #CACACA;
    border-radius: 5px;
    margin-bottom: 32px;
    display: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: $mainSecondColor;

      & > p {
        color: $mainSecondColor;
      }
    }

    @media (max-width: 1000px) {
      display: block;
    }

    p {
      padding: 26px;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      color: $buttonColor;
      transition: all 0.3s ease-in-out;

      @media (max-width: 720px) {
        padding: 16px;
      }
    }
  }
}


.Modal {
  position: absolute;
  z-index: 100;
  max-width: 496px;
  min-width: 300px;
  min-height: 488px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}
