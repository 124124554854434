@import "../../scss/variables";

.choose-filter {
  &__head {
    font-size: 18px;
    line-height: 28px;
    color: $textColor;
    margin: 16px 0;
  }

  &__body {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $backColor;
    border: 1px solid $mainColor;
    border-radius: 40px;
    padding: 0 12px;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 12px;
    line-height: 20px;
    margin-right: 10px;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      & > svg {
        width: 26px;
        height: 26px;
      }
    }

    & > svg {
      transition: all .3s ease;
    }

  }

  &__clear {
    color: $mainSecondColor;
    margin-bottom: 32px;
    cursor: pointer;
    width: 100px;
    transition: all .3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: solid 1px $lineColor;
    margin-bottom: 16px;
  }
}
