@import "../../scss/variables";

.card {
  &-wrapper {
    max-width: 33.333%;
    flex: 0 0 33.333%;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
    //align-self: stretch;

    @media (max-width: 624px) {
      max-width: 50%;
      flex: 0 0 50%;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 16px;
    }
  }
}
