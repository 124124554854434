@import "../../scss/variables";

.searchForm {
  max-width: 872px;
  height: 232px;
  background-color: $whiteColor;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 34px 48px;

  @media (max-width: 876px) {
    height: 292px;
  }

  @media (max-width: 730px) {
    margin: 0 auto;
    height: auto;
    padding: 24px 16px;
  }

  .advancedSearch {
    text-decoration: none;
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $textColor;

    & > div {
      align-items: center;
      display: flex;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }

    @media (max-width: 690px) {
      justify-content: flex-end;
    }

    &-icon {
      margin-right: 14px;
    }
  }
}