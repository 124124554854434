// general
@import "scss/reset";
@import "scss/fonts";
@import "scss/variables";

// checkbox
@import "scss/checkbox";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: $textColor;
  font-size: 14px;
  line-height: 20px;
}

main {
  min-height: calc(100vh - 216px);
  @media (max-width: 672px) {
    min-height: calc(100vh - 172px);
  }
}

.app-container {
  max-width: 1320px;
  margin: 0 auto;

  @media (max-width: 1360px) {
    margin: 0 12px;
  }
}

