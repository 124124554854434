@import "../../scss/variables";

.not-active {
  opacity: 0.5;
}

.card {
  &-wrapper {
    &-loader {
      min-height: 453px;
    }

    &-card {
      border: 1px solid $lineColor;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
      }

      &:hover .card-button {

      }
    }

    &-content {
      width: 100%;
      padding: 32px 32px 16px 32px;
      position: relative;

      @media (max-width: 1000px) {
        padding: 16px;
      }
      @media (max-width: 624px) {
        padding: 12px;
      }
    }
  }

  &-shop-name {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;

    @media (max-width: 624px) {
      width: 47px;
      height: 16px;
    }
  }

  &_image-wrapper {
    width: 100%;
    height: 248px;
    @media (max-width: 624px) {
      height: 159px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 28px;
    margin: 12px 0;
    min-height: 100px;
    width: 100%;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $buttonColor;
    }

    @media (max-width: 624px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 624px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &-price {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $titleColor;

    @media (max-width: 624px) {
      margin-bottom: 8px;
    }
  }

  &-shipping {
    color: $buttonColor;
  }

  &-size {
    color: $buttonColor;

    &-content {
      background-color: $backColor;
      padding: 16px 32px;
      width: 100%;

      @media (max-width: 842px) {
        padding: 12px 12px;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-title {
      margin-right: 12px;
    }

    &-out {
      color: $placeholderColor;
      text-align: end;
      margin-left: 10px;

      @media (max-width: 842px) {
        display: none;
      }
    }
  }

  &-button {
    width: 100%;
    cursor: pointer;
    color: $mainSecondColor;
    padding: 8px 24px;
    border: 1px solid $mainSecondColor;
    border-radius: 5px;
    margin-top: 16px;
    transition: all .3s ease-in-out;

    &:hover {
      color: $whiteColor;
      background-color: $mainSecondColor;
    }

    @media (max-width: 842px) {
      padding: 8px 12px;
    }
  }

  &-link {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $mainSecondColor;
    padding: 8px 24px;
    border: 1px solid $mainSecondColor;
    border-radius: 5px;
    margin-top: 16px;
    transition: all .3s ease-in-out;

    &:hover {
      color: $whiteColor;
      background-color: $mainSecondColor;
    }

    @media (max-width: 842px) {
      padding: 8px 12px;
    }
  }

  &_loader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover .load-more {
      rotate: 360deg;
    }

    &:hover svg path {
      stroke: $buttonColor;
    }

    &:hover .card_loader-title {
      color: $buttonColor;
    }

    &-title {
      margin-top: 20px;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }
}

.load-more {
  rotate: 0deg;
  transition: rotate 0.8s ease-in-out;
}
