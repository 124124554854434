@import "../../../scss/variables";

.entered-size {
  margin-bottom: 48px;
  background-color: $backColor;
  border-radius: 10px;
  padding: 24px 30px 24px 30px;

  @media (max-width: 1060px) {
    padding: 16px 12px 16px 12px;
  }
  @media (max-width: 600px) {
    margin-bottom: 24px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-right: 30px;
  }

  &__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textColor;
    margin-bottom: 8px;
  }

  &__value {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $titleColor;

    @media (max-width: 570px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: all .3s ease-in-out;
    cursor: pointer;
    &:hover,&:active {
      border: 1px solid $buttonColor;
    }
  }

  &__form {
    padding-top: 36px;
    height: auto;
    opacity: 1;
    visibility: visible;
    transition: all 0.6s ease-in-out;

    &-closed {
      height: 0;
      opacity: 0;
      visibility: hidden;
      //transition: all 0.4s ease;
    }
  }
}
