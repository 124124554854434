$titleColor: #0E2F4B;
$textColor: #53565A;
$whiteColor: #ffffff;
$backColor: #F7F7F7;
$mainColor: #BAD0D3;
$mainSecondColor: #855B50;
$footerColor: #E4DED4;
$buttonColor: #506D85;
$inputColor: #CACACA;
$placeholderColor: #ADADAD;
$lineColor: #EEEEEE;