@import "../../../scss/variables";

.main-content {
  margin: 100px 0;

  &__info {
    background: $backColor;
    border-radius: 30px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    @media (max-width: 712px) {
      flex-direction: column-reverse;
      margin-bottom: 60px;
    }
  }

  &__crop {
    align-self: stretch;
    width: auto;
  }

  &__box {
    max-width: 600px;
    margin: 48px;

    @media (max-width: 1160px) {
      margin: 24px;
    }

    @media (max-width: 1060px) {
      max-width: 500px;
    }


  }

  &__image {
    width: 100%;
    height: 100%;
    min-width: 192px;
    object-fit: cover;
  }

  &__columns {
    display: flex;
    justify-content: space-between;

    @media (max-width: 712px) {
      flex-direction: column;
    }

    & * {
      max-width: 424px;
      margin-right: 24px;

      @media (max-width: 712px) {
        margin-bottom: 16px;
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-right: 0;

        @media (max-width: 712px) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: $titleColor;
    margin-bottom: 24px;

    @media (max-width: 1060px) {
      font-size: 24px;
      margin-bottom: 16px;
    }

  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $textColor;

    @media (max-width: 1060px) {
      font-size: 16px;
    }
  }
}
