@import "../../scss/variables";

.filter {
  display: flex;
  flex-direction: column;
  &::after {
    content: "";
    width: 100%;
    border-bottom: solid 1px $lineColor;
  }
  &__list {
    position: relative;
    margin-bottom: 16px;
    max-height: 366px;
    overflow: auto!important;
    &::-webkit-scrollbar {
      display: block;
      width: 8px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: $lineColor;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $placeholderColor;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $placeholderColor;
      cursor: pointer;
    }
  }
  &__show-all {
    font-size: 14px;
    line-height: 20px;
    color: $buttonColor;
    cursor: pointer;
    margin-bottom: 16px;
  }
}
