@import "../../scss/variables";

.catalog-page {
  display: flex;
  justify-content: space-between;
}

.catalog-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.catalog-no-results {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 28px;
  color: $placeholderColor;
}
