@import "../../../scss/variables";

.banner {
  width: 100%;
  background-color: $mainColor;

  &__container {
    margin: 0 auto;
    max-width: 1320px;
    height: 100%;
    padding: 30px 0;
    background-image: url("../../../assets/images/banner.png");
    background-repeat: no-repeat;
    background-position: right;

    @media (max-width: 1340px) {
      margin: 0 12px;
    }

    @media (max-width: 1000px) {
      background-image: none;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__inner {
    max-width: 536px;
  }

  &__title {
    font-weight: 500;
    font-size: 44px;
    line-height: 64px;
    margin-bottom: 24px;
    color: $titleColor;

    @media (max-width: 690px) {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    color: $textColor;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;

    @media (max-width: 690px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
}
