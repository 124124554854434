@import "../../../scss/variables";

.modal-delivery {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: rgba(23, 22, 22, 0.6);
  opacity: 1;
  visibility: visible;
  transition: all 0.6s ease-in-out;
  @media (max-width: 720px) {
    padding: 0 20px;
  }

  &--closed {
    opacity: 0;
    visibility: hidden;
  }

  &__container {
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 630px;
    //width: 100%;
    padding: 0;
    background-color: $whiteColor;
    max-height: 452px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
      width: 340px;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 450px) {
      max-height: 340px;
    }

  }
  &__left {
    height: 100%;
    width: 250px;
    @media (max-width: 800px) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (max-width: 800px) {
      padding: 0;
      width: 100%;
    }
  }
  &__title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: $titleColor;
    margin-bottom: 16px;
    margin-top: 32px;

    @media (max-width: 775px) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 12px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;

    @media (max-width: 775px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
  &__close-button {
    cursor: pointer;
    color: $mainSecondColor;
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
  &__close {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      width: fit-content;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
