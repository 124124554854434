@import "../../scss/variables";

.cookie-policy {
  padding: 20px 0;

  &__container {
    background-color: $backColor;
    border-radius: 15px;
    padding: 20px;

    & > div {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: $titleColor;
    margin-bottom: 24px;

    @media (max-width: 1060px) {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $titleColor;
    margin-bottom: 12px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $textColor;
    margin-bottom: 20px;

    @media (max-width: 1060px) {
      font-size: 16px;
    }
  }

  &__list {
    margin-left: 12px;

    li {
      position: relative;
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: $textColor;

      &:before {
        left: -12px;
        top: 10px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        content: "";
        background: $textColor;
        position: absolute;
      }
    }
  }

  &__link {
    text-decoration: none;
    color: $mainSecondColor;
    cursor: pointer;
  }
}
