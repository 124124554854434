.app-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & > p {
    display: block;
    font-size: 14px;
    color: $placeholderColor;
    margin-left: 8px;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .app-checkbox__label::before {
      border-color: $buttonColor;
      background-color: $buttonColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }

  &__label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $textColor;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid $inputColor;
      border-radius: 0.25em;
      margin-right: 12px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
}
