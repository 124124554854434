@import "../../scss/variables";

@import "../../scss/variables";

.price-filter {
  display: flex;
  flex-direction: column;

  &-wrapper {
    width: 250px;
  }

  &::after {
    content: "";
    width: 100%;
    border-bottom: solid 1px $lineColor;
  }

  &__range {
    max-width: 200px;
    display: flex;
    margin: 16px 0;
    align-items: center;
    justify-content: space-between;

    & > span {
      color: $inputColor;
    }
  }

  &__input {
    max-width: 91px;
    height: 36px;
    padding: 8px 12px;
    border: 1px solid $inputColor;
    border-radius: 5px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__button {
    color: $mainSecondColor;
    width: 100%;
    padding: 8px 10px;
    max-width: 68px;
    border: 1px solid $mainSecondColor;
    border-radius: 5px;
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $whiteColor;
      background-color: $mainSecondColor;
    }
  }

  .MuiSlider-root {
    margin: 0 10px;
    width: 180px;
  }

  .MuiSlider-root .MuiSlider-rail {
    color: $lineColor;
    opacity: 1;
  }

  .MuiOutlinedInput-root .Mui-focused {
    color: $lineColor;
  }

  .MuiSlider-root .MuiSlider-track {
    color: $buttonColor;
  }

  .MuiSlider-root .MuiSlider-thumb {
    color: $buttonColor;
  }

  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: none;
  }

  //.MuiSlider-root .MuiSlider-thumb .MuiSlider-thumbColorPrimary .PrivateValueLabel-open-2 {
  //  color: transparent;
  //}

}


