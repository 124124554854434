@import "../../scss/variables";

.filter-simple {
    display: flex;
    flex-direction: column;
    &::after {
      content: "";
      width: 100%;
      border-bottom: solid 1px $lineColor;
    }
}
