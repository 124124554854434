@import "../../scss/variables";

.header {
  padding: 37px 0;
  @media (max-width: 672px) {
    padding: 15px 0;
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }

  .logo-link {
    text-decoration: none;
    color: $titleColor;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      margin-left: 12px;
      @media (max-width: 672px) {
        display: none;
      }
    }
  }

}

