@import "../../scss/variables";

.catalog-filter {
  min-width: 200px;
  max-width: 320px;
  margin-right: 24px;
  transition: all 1s ease;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  background-color: $whiteColor;

  @media (max-width: 1000px) {
    position: fixed;
    height: 100%;
    right: -250px;
    top: 0;
    background-color: $whiteColor;
    margin: 0;
    min-width: 250px;
    padding: 32px;
    z-index: 2;
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &--active {
    right: 0;
  }
}

