@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Bold.eot');
  src: local('Rubik Bold'), local('Rubik-Bold'),
  url('../assets/fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Bold.woff2') format('woff2'),
  url('../assets/fonts/Rubik-Bold.woff') format('woff'),
  url('../assets/fonts/Rubik-Bold.ttf') format('truetype'),
  url('../assets/fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-SemiBold.eot');
  src: local('Rubik SemiBold'), local('Rubik-SemiBold'),
  url('../assets/fonts/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Rubik-SemiBold.woff') format('woff'),
  url('../assets/fonts/Rubik-SemiBold.ttf') format('truetype'),
  url('../assets/fonts/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-ExtraBold.eot');
  src: local('Rubik ExtraBold'), local('Rubik-ExtraBold'),
  url('../assets/fonts/Rubik-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-ExtraBold.woff2') format('woff2'),
  url('../assets/fonts/Rubik-ExtraBold.woff') format('woff'),
  url('../assets/fonts/Rubik-ExtraBold.ttf') format('truetype'),
  url('../assets/fonts/Rubik-ExtraBold.svg#Rubik-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Regular.eot');
  src: local('Rubik Regular'), local('Rubik-Regular'),
  url('../assets/fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Regular.woff2') format('woff2'),
  url('../assets/fonts/Rubik-Regular.woff') format('woff'),
  url('../assets/fonts/Rubik-Regular.ttf') format('truetype'),
  url('../assets/fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Black.eot');
  src: local('Rubik Black'), local('Rubik-Black'),
  url('../assets/fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Black.woff2') format('woff2'),
  url('../assets/fonts/Rubik-Black.woff') format('woff'),
  url('../assets/fonts/Rubik-Black.ttf') format('truetype'),
  url('../assets/fonts/Rubik-Black.svg#Rubik-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Medium.eot');
  src: local('Rubik Medium'), local('Rubik-Medium'),
  url('../assets/fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Medium.woff2') format('woff2'),
  url('../assets/fonts/Rubik-Medium.woff') format('woff'),
  url('../assets/fonts/Rubik-Medium.ttf') format('truetype'),
  url('../assets/fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Light.eot');
  src: local('Rubik Light'), local('Rubik-Light'),
  url('../assets/fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Light.woff2') format('woff2'),
  url('../assets/fonts/Rubik-Light.woff') format('woff'),
  url('../assets/fonts/Rubik-Light.ttf') format('truetype'),
  url('../assets/fonts/Rubik-Light.svg#Rubik-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

