@import "../../scss/variables";

.modal {
  &-wrapper-stock {
    padding: 48px;
    height: 100%;
    @media (max-width: 875px) {
      padding: 24px;
    }
  }

  &-wrapper {
    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        color: $titleColor;
        margin-bottom: 16px;
        width: 100%;
        max-width: 256px;

        @media (max-width: 775px) {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 12px;
        }
      }
    }
  }

  &-text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;

    @media (max-width: 775px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }


  &-input {
    padding: 14px 28px;
    border: 1px solid $inputColor;
    border-radius: 5px;
    width: 100%;

    @media (max-width: 775px) {
      padding: 16px;
    }

    &::placeholder {
      font-size: 18px;
      line-height: 28px;
      color: $placeholderColor;

      @media (max-width: 775px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &-close-stock {
    cursor: pointer;
    width: fit-content;
  }

  &-button {
    cursor: pointer;
    color: $mainSecondColor;
    padding: 16px 32px;
    border: 1px solid $mainSecondColor;
    border-radius: 5px;
    margin-top: 16px;
    background-color: transparent;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    transition: all 1s ease;

    &:hover {
      color: $whiteColor;
      background-color: $mainSecondColor;
    }
  }

  &-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  &-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .modal-label::before {
      border-color: $buttonColor;
      background-color: $buttonColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }

  &-label {
    display: flex;
    cursor: pointer;
    color: $textColor;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;

    &::before {
      content: '';
      display: inline-block;
      min-width: 20px;
      height: 20px;
      border: 1px solid $inputColor;
      border-radius: 0.25em;
      margin-right: 12px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
}

.size {
  color: $buttonColor;
}
