@import "../../scss/variables";

.sort {
  &-wrapper {
    padding: 16px 32px 4px 0;

    & > * {
      font-family: 'Rubik';
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
      cursor: pointer;
      padding-left: 32px;
      position: relative;

      &:hover {
        color: $buttonColor;
      }
    }
  }

  &-active {
    color: $buttonColor;
    &::before {
      content: url(../../assets/images/sort.svg);
      width: 12.5px;
      height: 8.3px;
      margin-right: 10px;
      position: absolute;
      left: 8px;
    }
  }
}

.MuiPaper-elevation8 {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05)
}

